body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: sans-serif;
}

.App {
  background-image: url("./assets/bg.png");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.TypeWriter-container {
  width: 60vw;
  height: 50vh;
  border: 2px solid rgba(255, 255, 255, 0.728);
  border-radius: 14px;
  background-color: #0000002f;
  padding: 10px;
  backdrop-filter: blur(10px);
}

.TypeWriterAnimation {
  color: white;
  text-align: justify;
  width: 100%;
}

.bottom {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.questionInput {
  width: calc(60vw - 80px);
  height: 50px;
  border: 2px solid rgba(255, 255, 255, 0.728);
  border-radius: 14px;
  background-color: #0000002f;
  padding: 10px;
  color: white;
  font-size: 20px;
  backdrop-filter: blur(10px);
}

.questionInput:focus {
  outline: none;
}

.questionInput::placeholder {
  color: white;
  font-size: 20px;
}

.sendButton {
  width: 50px;
  height: 50px;
  border: 2px solid rgba(255, 255, 255, 0.728);
  border-radius: 14px;
  background-color: #ffffff2f;
  padding: 10px;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sendButton:hover {
  background-color: #ffffff4f;
}

.sendButton:active {
  background-color: #ffffff6f;
}

.sendImg {
  width: 40px;
  height: 40px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
